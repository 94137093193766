
<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import { VueEditor } from 'vue2-editor'
import VideoInput from 'vue-video-input'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Create Paid Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    VideoInput,
  },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      options: [],
      form: {
        show: { name: 'show', value: true },
        content_title: '',
        content_text: '',
        image: '',
        inputtag: [],
        video: '',
        thumnail: '',
      },
      options2: [
        { name: 'show', value: true },
        { name: 'hide', value: false },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      isEdit: false,
      ismanuallyadd: false,
    }
  },
  validations: {
    form: {
      content_title: {
        required,
      },
      content_text: {
        required,
      },
      image: {
        required,
      },
    },
  },
  mounted() {
    this.initialUpdateItem()
    this.getContenttags()
  },
  methods: {
    vfileAddedThumbnail(file) {
      this.form.thumnail = file
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async getContenttags() {
      const data = await axios
        .get('/api/contenttag')
        .then((res) => res.data)
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })

      this.options = data
    },
    async formSubmit() {
      // if (!this.form.content_title) {
      //   this.$toast.error('title field must not be empty ')
      // }
      // this.$v.form.$touch()
      // if (this.$v.form.$anyError) {
      //   return
      // }

      let id = []
      this.form.inputtag.map((contenttag) => {
        id.push(contenttag._id)
      })

      let body = {
        content_title: this.form.content_title,
        content_text: this.form.content_text,
        hashtag: id,
        status: this.form.show.value,
      }

      const data = new FormData()

      if (!this.ismanuallyadd) {
        let img = this.form.image
        data.append('image', img)
      }
      if (this.form.video) {
        data.append('video', this.form.video)
      }

      if (this.form.thumnail) {
        data.append('thumnail', this.form.thumnail)
      }

      data.append('data', JSON.stringify(body))
      const queryId = this.$route.query.id
      const request = !this.isEdit
        ? axios.post('/api/paidcontent', data)
        : axios.patch(`/api/content/${queryId}`, data)

      await request
        .then((result) => {
          this.$toast.success('Saved successfully')
          // this.$router.push('/contents/paidcontent')
        })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })
    },
    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios.get(`api/paidcontent/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }

        this.form.status
          ? (this.form.show = { name: 'show', value: true })
          : (this.form.show = { name: 'hide', value: false })

        this.form.inputtag = res.data.hashtag
        this.isEdit = true

        var file = { size: 123, name: 'Icon', type: 'image/png' }
        var url = `${this.baseImageUrl}/${this.form.img_url}`
        this.$refs.myVueDropzone.manuallyAddFile(file, url)
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Create Paid Content</h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        v-model="$v.form.content_title.$model"
                        :state="validateState('content_title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">This is a required field</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>
                        Video
                        <small
                          class="color-text-small"
                        >Just only one , Maximum file size is 500MB</small>
                      </label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather type="video" class="icon-dual icon-xs align-middle"></feather>
                        </b-input-group-prepend>
                        <b-form-file id="form-video" accept="video/*" v-model="form.video"></b-form-file>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>
                        Thumbnail
                        <small
                          class="color-text-small"
                        >Width 170 x Height 120. Just only one.</small>
                      </label>
                      <vue-dropzone
                        @vdropzone-file-added="vfileAddedThumbnail"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                      ></vue-dropzone>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Text</label>
                      <vue-editor height="300" v-model="$v.form.content_text.$model"></vue-editor>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>
                        Image
                        <small class="color-text-small">Width 354 x Height 338. Just only one.</small>
                      </label>

                      <vue-dropzone
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                        id="form-image"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                        :state="validateState('image')"
                        aria-describedby="input-3-live-feedback"
                      ></vue-dropzone>
                      <b-form-invalid-feedback id="input-3-live-feedback">This is a required field</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="2" label="Hashtag :">
                      <div>
                        <multiselect
                          v-model="form.inputtag"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search"
                          label="tag_name"
                          track-by="_id"
                          :options="options"
                          :multiple="true"
                        ></multiselect>
                      </div>
                    </b-form-group>

                    <b-form-group label-cols-lg="2" label="Status :">
                      <div>
                        <multiselect
                          v-model="form.show"
                          deselect-label="Can't remove this value"
                          track-by="name"
                          label="name"
                          placeholder="Select one"
                          :options="options2"
                          :searchable="false"
                          :allow-empty="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                        </multiselect>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <router-link to="/contents/paidcontent">
                        <button type="button" class="btn btn-danger float-right ml-2">Cancel</button>
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right">Save</button>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
